<template lang="pug">
    .order_wrapper
        .batch-set(v-if="batchOn")
            van-checkbox(v-model="selectAll" shape="square" icon-size="15px" style="flex:1" checked-color="#dd4433" @change="changeSelectAll")
                span.f13 全选(
                span.ml5.mr5 {{selectList.length}}
                span.f13 )
            van-button.fr.mr8(size="small" type="primary" @click="print(selectList)") 批量打印
            van-button.fr(size="small" type="primary" @click="send(selectList)") 批量发货
        van-tabs(v-model="form.orderStatus" @change="changeCond")
            van-tab(title="待发货" :name="1")
            van-tab(title="已发货" :name="2")
            van-tab(title="退款中" :name="3")
            van-tab(title="全部" :name="4")
        .filters
            .filter(:class="{active: batchOn}" @click="batchOn = !batchOn")
                span {{batchOn ? '取消批量':'开启批量'}}
            van-dropdown-menu.pct50
                van-dropdown-item(v-model="form.printStatus" :options="printRange" @change="changeCond")
                van-dropdown-item(v-model="pageReq.sort" :options="sortRange" @change="changeCond")
            .filter(@click="popupShow.conditions = !popupShow.conditions") 筛选
        .tips
            .tip
                .c 共查询到&nbsp;
                    span.red {{totalOrderNum}}
                    | &nbsp;个 待发货未打印 订单，共&nbsp;
                    span.red {{total}}
                    | &nbsp;个包裹
        van-list.order_list(
        v-if="orders && orders.length > 0"
        v-model="loadingObj.loading"
        :finished="loadingObj.finished"
        :finished-text="loadingObj.finishedText"
        @load="onLoad"
        )
            van-checkbox-group(v-model="selectList" ref="checkboxGroup")
                .order(v-for="it in orders" :key="it.orderSn")
                    .order_row1.flex_center
                        .or1l
                            .info
                                van-checkbox.fl.mr5(:name="it.orderSn" shape="square" icon-size="15px" v-if="batchOn" checked-color="#dd4433")
                                span {{it.orderSn}}
                                copy-op.middle_font(:text="it.orderSn")
                            .tags.mt4
                                .tag.merge_tag(v-if="it.mergeOrders") 合单{{it.mergeOrders.length + 1}}
                                .tag.warn_tag(v-if="it.isLike === 1" title="相同手机号") 同号
                                .tag.error_tag(v-if="it.addressSpecial===2" title="详细地址存疑") 疑
                                .tag.error_tag(v-if="it.riskControlStatus" title="风控订单") 风控
                                .tag.black_tag(v-if="it.isBlack" title="黑名单用户") 黑名单
                                .tag(v-if="it.refundStatus > 1") {{it.refundStatus === 2 ? '售后' : '退'}}
                        .ol1r
                            .send_status.flex_center
                                van-circle(v-if="it.orderStatus === 1 && it.refundStatus === 1"
                                v-model="it.period" :size="14" :stroke-width="150" :rate="30" :speed="100"
                                :color="it.totalHour < 12 ? '#DD4433': '#19e'"
                                layer-color="#ccc")
                                .color_red {{orderStatusMap[it.orderStatus]}}
                                .color_green(v-if="it.printStatus") 已打印
                            .tip.mt4.info(v-if="it.orderStatus === 1 && it.refundStatus === 1") {{it.leftTime}}
                    div
                        .order_row2
                            img.prod_img(:src="it.goodsImg")
                            .prod_info
                                .van-multi-ellipsis--l2 {{it.goodsName}}
                                .color_red.small_font.mt4 {{it.goodsSpec}}
                            .price
                                dynamic-text ¥{{it.payAmount}}
                                dynamic-text.color_red.tr x{{it.goodsCount}}
                        .order_row2(v-for="it2 in it.mergeOrders" :key="it2.orderSN")
                            img.prod_img(:src="it2.goodsImg")
                            .prod_info
                                .van-multi-ellipsis--l2 {{it2.goodsName}}
                                .color_red.small_font.mt4 {{it2.goodsSpec}}
                            .price
                                dynamic-text ¥{{it2.payAmount}}
                                dynamic-text.color_red.tr x{{it2.goodsCount}}
                        .tr.info
                            | 共计&nbsp;
                            span.color_red.middle_font {{it.totalGoodsCount}}
                            | &nbsp;件，邮费￥0.00，实付&nbsp;
                            span.color_red.middle_font ¥{{it.totalPayAmount}}
                    .order_cell
                        .flex_center
                            i.iconfont.icon-service_point.order_logo
                            .cont
                                div {{it.receiverNameEnc}} {{it.receiverPhoneEnc}}
                                div {{it.province}}{{it.city}}{{it.town}}{{it.receiverAddressEnc}}
                        // i.iconfont.icon-you
                    .order_cell
                        .flex_center
                            i.iconfont.icon-flag1.order_logo
                            .cont(v-if="it.remark") {{it.remark}}
                            .info(v-else) 暂无备注
                        // i.iconfont.icon-you
                    .order_cell
                        .flex_center
                            i.iconfont.icon-send01.order_logo
                            template(v-if="it.waybill.wpSendCode")
                                .cont {{it.waybill.wpName}} {{it.waybill.wpSendCode}}
                                copy-op.middle_font(:text="it.waybill.wpSendCode")
                            .info(v-else) 暂无快递单号
                    .op
                        div
                            // i.iconfont.icon-menu
                        div(v-if="!batchOn")
                            van-button.mr8(type="primary" size="small" @click="print([it])") 再次打印
                            van-button(type="primary" size="small" @click="send([it])") 发货
        emptyView(v-else)
        van-calendar(
        ref="vanCalendar"
        v-model="popupShow.timeSelect"
        type="range"
        :default-date="form.dateRange"
        :max-range="30"
        :allow-same-day="true"
        :min-date="dateLimit[0]"
        :max-date="dateLimit[1]"
        @confirm="onTimeChangeConfirm")
        van-popup(v-model="popupShow.fastShow" position="bottom" style="width: 100%;height: 35%" get-container="body" )
            van-picker(title="快捷选择"
            show-toolbar
            :visible-item-count="4"
            :columns="fastFilterOptions"
            value-key="label"
            :columns-field-names="{text: 'label'}"
            @cancel="popupShow.fastShow = false"
            @confirm="changeFastOp"
            )
        van-popup(v-model="popupShow.provinceShow" position="bottom" style="width: 100%;height: 35%" get-container="body" )
            van-picker(title="收件人省份"
            show-toolbar
            :visible-item-count="4"
            :columns="provinces"
            @cancel="popupShow.provinceShow = false"
            @confirm="changeProvince"
            )
        van-popup(v-model="popupShow.conditions" position="right" style="height:100%;width: 80%;")
            .conds_wrapper
                .cond_item
                    .title 下单时间
                    .cent
                        .cent_item(v-for="op in timeOps" :class="{active: form.timeOption === op.value }" @click="changeTimeOp(op)") {{op.text}}
                    van-cell(title="选择日期区间" :value="dateRangeStr" @click="showCalendar")
                .cond_item.flex_center
                    .title 剩余时间
                    .value.tags
                        .tag(:class="{active: 'all' === form.remainTimeOp}" @click="form.remainTimeOp = 'all'") 所有
                        .tag(:class="{active: '3h' === form.remainTimeOp}" @click="form.remainTimeOp = '3h'") 3小时
                        .tag(:class="{active: 'ot' === form.remainTimeOp}" @click="form.remainTimeOp = 'ot'") 已超时
                .cond_item.flex_center
                    .title 快捷选择
                    .value.tr.flex_one(@click="popupShow.fastShow = !popupShow.fastShow")
                        | {{this.form.fastOption ? this.form.fastOption.label : '全部'}}
                        van-icon.ml16(name="arrow-down")
                .cond_item.flex_center
                    .title 收件人省份
                    .value.tr.flex_one(@click="popupShow.provinceShow = true")
                        | {{this.form.province || '全部'}}
                        van-icon.ml16(name="arrow-down")
                .cond_item.flex_center
                    .title 旗帜
                    .cent.flex_one
                        i.iconfont.icon-flag(v-for="f in flagList" :class="['flag_' + f.value, f.value === form.flag ? 'checked': '']" @click="changeFlag(f)")
                .footer
                    van-button(type="primary" hairline plain size="small" long @click="removeCond") 重置
                    van-button(type="primary" hairline  size="small" long @click="changeCond") 确认
        beforePrint(ref="beforePrint")
</template>

<script>
  import constant from "@/service/constant";
  import {mapGetters} from "vuex";
  import DynamicText from "@/components/DynamicText";
  import CopyOp from "@/components/CopyOp";
  import config from "@/common/config";
  import beforePrint from "./beforePrint"
  import {Toast} from 'vant';
  import Tabs from "@/view/layout/tabs";
  import utils from "@/common/utils";
  import emptyView from '../common/emptyView'
  import supportTemplate from '@/bluetooth/supportTemplate'
  import errorView from '@/common/errorView'


  const blackPhoneField = 'blackPhoneType', fastFilterOptions = [
    {value: 'mergeStatus=1', label: '合单订单'},
    {value: 'mergeStatus=2', label: '非合单订单'},
    {value: 'mergeStatus=3', label: '同一电话多地址订单'},
    // {value: 'orderFlag=1', label: '异常订单'},
    {value: 'addressSpecial=2', label: '疑似偷运费订单'},
    {value: blackPhoneField + '=4', label: '黑名单订单'},
    // {value: blackPhoneField + '=5', label: '非黑名单订单'},
    {value: 'printStatus2=1', label: '已取号'},
    {value: 'printStatus2=0', label: '未取号'},
    {value: 'isPreSale=1', label: '预售订单'},
    {value: 'isPreSale=0', label: '非预售订单'},
    // {value: 'preShipment=1', label: '预发货订单'},
    {value: 'hasPromise=1', label: '有承诺'},
    //{value: 'hasPromise=0', label: '无承诺'},
    {value: 'locked=1', label: '已锁定'},
    {value: 'locked=0', label: '未锁定'},
    {value: 'isShare=1', label: '已分配给厂家'},
    //{value: 'isShare=0', label: '未分配'},
  ];
  const phoneIndexField = 'receiver_phone_index',
    inOrEqualOption = ['receiver_name_index', phoneIndexField, 'order_sn', 'waybill_code'],
    likeOption = ['pop_remark', 'remark'],
    rangeOption = ['goods_count', 'goods_price', 'pay_amount'];
  export default {
    name: "index",
    components: {Tabs, CopyOp, DynamicText, beforePrint, emptyView},
    data() {
      return {
        selectAll:false,
        selectList:[],
        popupShow: {
          conditions: false,
          timeSelect: false,
          fastShow: false,
          provinceShow: false
        },
        dateLimit: [new Date(Date.now() - 86400000 * 90), new Date()],
        active: 0,
        currentRate: 30,
        printRange: [
          {text: '打印状态', value: -1},
          {text: '已打印', value: 1},
          {text: '未打印', value: 0},
        ],
        sortRange: [
          {text: '后创建的在前', value: 'confirm_time,desc'},
          {text: '先创建的在前', value: 'confirm_time,asc'},
          {text: '商品名称规格升序', value: 'goods_name,goods_spec,desc'},
          {text: '商品名称规格降序', value: 'goods_name,goods_spec,asc'},
        ],
        timeOps: [
          {text: '今天', value: 1},
          {text: '昨天', value: 2},
          {text: '近三天', value: 3},
          {text: '近7天', value: 7},
          {text: '近30天', value: 30},
        ],
        fastFilterOptions,
        provinces: [],
        flagList: constant.flagList,
        orderStatusMap: config.statusMap,
        batchOn: false,
        form: {
          orderStatus: 1,
          printStatus: -1,
          timeOption: 3,
          dateRange: [],
          fastOption: null,
          province: null,
          remainTimeOp: null,
          flag: null
        },
        req: {
          ownerIdList: null,

          mergeStatus: null, // 合并状态。1-合并订单, 2-非合并订单, 3-相同手机号不同地址
          exceptionStatus: null, // 异常订单 【保留字段】
          addressSpecial: null, // 异常地址 【骗运费地址，村镇地址】
          /* {
            range: [], // 范围
            column: "", // 范围字段 可以查询 "order_sn", "receiver_phone_index", "receiver_name_index", "waybill_code", "pop_remark", "remark", "goods_id", "sku_id"
            type: 0 // type 查询类型 {0: 'in', 1: 'not in', 2: 'like', 3: 'not like'}
          } */
          ranges: [], //范围列表
          printStatus2: null, // 取号状态
          isPreSale: null, // 是否预售
          hasPromise: null, // 是否有承诺
          locked: null, // 是否锁定
          isShare: null, // 是否分配，0,1,具体的厂家

          orderStatus: null, // 订单状态
          printStatus: null, // 打印状态
          preShipment: null, // 预发货状态
          refundStatus: [], // 退款状态，1：无售后或售后关闭，2：售后处理中，3：退款中，4： 退款成功

          // 价格搜索
          priceField: 'goodsPrice', // 实付价格，商品价格
          priceStart: null, // 价格起
          priceEnd: null,  // 价格止

          search: '', // 智能搜索
          productCountStart: null, // 商品件数起
          productCountEnd: null,  // 商品件数止

          // 销售属性
          goodsIdCountStart: null, // 商品种类数起
          goodsIdCountEnd: null, // 商品种类数止
          skuIdCountStart: null, // sku 种类数起
          skuIdCountEnd: null,  // sku 种类数止

          // 时间范围
          timeField: 'confirm', // 时间类型
          startTime: null, // 范围起
          endTime: null, // 范围止

          remarkType: null, // 备注类型，有备注，无备注，有顺丰加价，无顺丰加价
          flag: null, // 旗帜类型

          // 快递分区
          provinceIds: null, // 省份ID 列表
          cityIds: null, // 城市Id 列表
          townIds: null, // 区县 Id 列表
          excludeType: null, // 0不包含 1包含
          // displayMerge: true, // 是否合并展示

          // 一键筛选字段
          goodsId: null, // 选中的商品
          skuIds: '', // 选中的sku
          goods: '', // 搜索商品关键字, 商品属性或商品名称具体的值
          goodsInclude: null, // 0 不包含  1包含
          goodsType: null, // 1 商品属性 goods_spec, 2 商品名称 goods_name
          skuAliasName: '', // sku简称， 精确查询
          goodsAliasName: '', // good 简称，精确查询
          outerGoodsId: '', // 商品编码 outer_goods_id
          outerId: '', // sku编码 outer_id

          /* TODO 删除字段
          wpCode: null,
          province: '',
          city: '',
          area: '',
          quickOption: null,
          */
        },
        pageReq: {
          page: 1,
          size: 10,
          sort: 'confirm_time,desc'
        },
        orders: [],
        total: 0,
        totalOrderNum: 0,
        loadingObj: {
          loading: false,
          finished: false,
          finishedText: '没有更多了'
        }
      }
    },
    computed: {
      ...mapGetters(['shopList', 'currentShop', 'mergeOrderFlag', 'blackList']),
      dateRangeStr() {
        const [start, end] = this.form.dateRange, format = window.DateFormat.format;
        if (!start) {
          return '';
        }
        return format.date(start, 'MM-dd') + '至' + format.date(end, 'MM-dd');
      }
    },
    created() {
      const {dateRange, timeFiled, orderTab} = this.$route.params;
      if (orderTab) {
        this.form.orderStatus = orderTab;
        if ('confirm' === timeFiled) {
          if (Number.isInteger(dateRange)) {
            this.changeTimeOp({value: dateRange});
          } else {
            this.form.timeOption = null;
            this.form.dateRange = dateRange;
          }
        } else {
          this.form.timeOption = null;
          this.form.remainTimeOp = dateRange;
        }
      } else {
        this.form.dateRange = this.convertTime();
      }
      const areaList = utils.get('areaList');
      this.provinces = ['全部'].concat(areaList.map(it => it.name));
      this.loadAll();
    },
    methods: {
      changeSelectAll(){
        if (this.selectAll) {
          this.selectList = this.orders.filter(it=>it.orderSn);
          this.$refs.checkboxGroup.toggleAll(true);
        } else {
          this.selectList = [];
          this.$refs.checkboxGroup.toggleAll(false);
        }
      },
      send(list) {
        if (list.length > 0) {
          list = this.orders.filter(it=>list.includes(it.orderSn));
        }
        if (list.length === 0) {
          Toast.fail('请先选择订单')
          return;
        }
        let failList = list.filter(it => !it.waybillCode);
        if (failList.length > 0) {
          Toast.fail(failList.length + '个订单还没有运单号，请先获取运单号后发货');
          return;
        }
        let paramList = [], logisticsCompanyMap = supportTemplate.logisticsCompanyMap;
        list.forEach(it => {
          paramList.push({
            orderSn: it.orderSn,
            waybillCode: it.waybillCode,
            wpCode: it.wpCode,
            logisticsId: logisticsCompanyMap[it.wpCode].id
          });
          if (it.mergeOrders && it.mergeOrders.length > 0) {
            it.mergeOrders.forEach(i => {
              paramList.push({
                orderSn: i.orderSn,
                waybillCode: i.waybillCode,
                wpCode: i.wpCode,
                logisticsId: logisticsCompanyMap[i.wpCode].id
              });
            })
          }
        })
        this.$http.sendOutGoodsAllShop(paramList).then(res=>{
          const results = res.data, fails = results.filter(it => it.code === 0);
          if (!results || results.length === 0) {
            return;
          }
          if (fails.length === 0) {
            Toast.success('发货成功！');
          } else {
            errorView.showSendGoodsError(results.length, fails, paramList, '发货').then(()=>{
              this.loadAll();
            })
          }
        })
      },
      onLoad() {
        this.loadingObj.loading = true;
        this.pageReq.page += 1;
        this.loadAll();
      },
      loadAll() {
        const {orderStatus, printStatus, fastOption, province, flag, dateRange, remainTimeOp} = this.form;
        const req = {...this.req, displayMerge: this.mergeOrderFlag}, pageReq = {...this.pageReq};
        req.ownerIdList = [this.currentShop.ownerId];

        const {prop, val} = this.changeFastFilter(fastOption ? fastOption.value : null);
        const ranges = [];
        req.ranges = ranges;
        if (prop === 'ranges') {
          ranges.push(val);
        } else {
          req[prop] = val;
        }
        if (province && province !== '全部') {
          req.areaConds = [{province}]
        }
        if (flag) {
          req.flag = flag;
        }
        pageReq.page -= 1;
        if (orderStatus === 1) {
          req.orderStatus = 1;
          req.refundStatus = [1];
        } else if (orderStatus === 2) {
          req.orderStatus = 2;
          req.refundStatus = [1];
        } else if (orderStatus === 3) {
          req.refundStatus = [2, 3, 4];
        }
        req.printStatus = printStatus === -1 ? null : printStatus;
        const timeRanges = [];
        const [start, end] = dateRange;
        if (start || end) {
          const confirmTimeRange = {timeField: 'confirm'};
          if (end) {
            confirmTimeRange.endTime = end.getTime(); // 范围起
          }
          if (start) {
            confirmTimeRange.startTime = start.getTime(); // 范围止
          }
          timeRanges.push(confirmTimeRange);
        }
        if (remainTimeOp) {
          const remainObj = {timeField: 'last_ship'};
          if (remainTimeOp === '3h') {
            remainObj.startTime = new Date(Date.now() - 3 * 60 * 60 * 1000).getTime();
            remainObj.endTime = Date.now();
          } else if (remainTimeOp === 'ot') {
            remainObj.startTime = Date.now();
          }
          timeRanges.push(remainObj);
        }
        req.timeRanges = timeRanges;
        this.$http.getAllPopOrders(req, pageReq).then(res => {
          const {content, totalElements} = res.data;
          if (pageReq.page === 0) {
            this.orders = content;
          } else {
            this.orders = this.orders.concat(content);
          }
          this.loadingObj.loading = false;
          this.loadingObj.finished = this.orders.length === totalElements;
          this.total = totalElements;
        });
        this.$http.queryOrderNum(req).then(res => {
          this.totalOrderNum = res.data;
        })
      },
      changeFastFilter(pv = -1) {
        if (pv === undefined || pv === null || pv === -1) {
          return {};
        }
        const [prop, v0] = pv.split('='), v = Number(v0);
        if (prop !== blackPhoneField) {
          return {prop, val: v};
        } else {
          if (!this.blackList || this.blackList.length === 0) {
            throw new Error("您还没有设置黑名单!");
          }
          const val = {
            column: phoneIndexField,
            range: this.blackList.map(it => it.phoneIndex),
            type: 0
          };
          return {prop: 'ranges', val};
        }
      },
      changeCond() {
        this.loadAll();
      },
      removeCond() {
        this.form.timeOption = 3;
        this.form.dateRange = this.convertTime();
        this.form.fastOption = null;
        this.form.province = null;
        this.form.remainTimeOp = null;
        this.form.flag = null;
      },
      showCalendar() {
        this.popupShow.timeSelect = true
      },
      changeTimeOp(op) {
        this.form.timeOption = op.value;
        this.form.dateRange = this.convertTime();
      },
      changeFlag(f) {
        if (this.form.flag === f.value) {
          this.form.flag = null;
        } else {
          this.form.flag = f.value;
        }
      },
      convertTime() {
        let start, end;
        const timeOption = this.form.timeOption, now = new Date(),
          nowEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
        if (timeOption === 1) {
          end = nowEnd; // 范围起
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // 范围止
        } else if (timeOption === 3) {
          end = nowEnd; // 范围起
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2); // 范围止
        } else if (timeOption === 7) {
          end = now; // 范围起
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6); // 范围止
        } else if (timeOption === 30) {
          end = nowEnd;
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 29);
        } else if (timeOption === 2) {
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59);
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
        }
        return [start, end];
      },
      onTimeChangeConfirm(value) {
        this.form.dateRange = value;
        this.form.timeOption = null;
        this.popupShow.timeSelect = false;
      },
      changeFastOp(op, index) {
        this.form.fastOption = op;
        this.popupShow.fastShow = false;
      },
      changeProvince(val, index) {
        this.form.province = val;
        this.popupShow.provinceShow = false;
      },
      print(checkedList) {
        if (checkedList.length > 0) {
          checkedList = this.orders.filter(it=>checkedList.includes(it.orderSn));
        }
        if (checkedList.length === 0) {
          Toast.fail('请选择要打印的订单')
          return;
        }
        if (checkedList.length > 2000) {
          Toast.fail('打印最多只能支持2000条')
          return;
        }
        let send = true;
        for (let it of checkedList) {
          if (it.orderStatus > 1 || it.refundStatus > 1 || it.printStatus > 0) {
            send = false;
          }
          if (!it.receiverPhoneIndex || !it.receiverNameIndex || !it.receiverAddressIndex) {
            const type = !it.receiverPhoneIndex ? '电话' : !it.receiverNameIndex ? '姓名' : '地址';
            Toast.fail('无' + type + '的订单' + it.orderSn + '不能打印！')
            return;
          }
        }
        //校验订单和快递模板
        const existWpCodes = new Set(checkedList.filter(it => it.wpCode).map(it => it.wpCode));
        if (existWpCodes.size > 1) {
          Toast.fail('不同快递公司不能一起打印')
          return;
        }
        if (existWpCodes.size === 1 && checkedList.filter(it => !it.wpCode).length > 0) {
          Toast.fail('已取号订单和未取号订单不能一起打印')
          return;
        }
        this.$refs['beforePrint'].boot(checkedList);
      },
    }
  }
</script>

<style lang="stylus">
    .order_wrapper.global_view
        padding 0 !important
        .batch-set
            width 100%
            height 50px
            background #f5f5f5
            position fixed
            bottom 50px
            z-index 999
            align-items center
            display flex
            padding 0.5rem

        .filters
            display flex
            align-items center

            .filter
                width 25%
                text-align center
                padding .5rem .5rem
                font-size 12px
                color #ccc
                background-color white

                &.active
                    color $active

            .van-dropdown-menu
                > .van-dropdown-menu__bar
                    height 30px
                    box-shadow none

                .van-dropdown-menu__title
                    font-size 12px

                .van-dropdown-menu__title:not(.van-dropdown-menu__title--active)
                    color #ccc

        .tips
            .tip
                padding .5rem
                display flex
                flex-wrap nowrap
                justify-content space-between
                font-size 12px

            .sync
                font-size 12px

        .order_list
            .order
                background-color white
                padding .5rem
                margin-bottom 1rem

                > div:not(:last-child)
                    padding 0 0 .5rem
                    border-bottom 1px solid $border_color
                    margin-bottom .5rem

                .order_row1
                    display flex
                    align-items center
                    justify-content space-between

                    .send_status > div
                        margin-right .25rem

                .order_row2
                    display flex
                    align-items top

                    .prod_img
                        width 100px
                        height 100px

                    .prod_info
                        padding-left 1rem
                        width calc(100% - 150px)

                    .price
                        width 50px
                        text-align right

                .order_row2 + .order_row2
                    margin-top .75rem

                .op
                    display flex
                    justify-content space-between

                .order_cell
                    display flex
                    justify-content space-between
                    align-items center
                    color $second_font_color

                    .cont
                        font-size 14px

                .order_logo
                    width 18px
                    height 18px
                    margin-right .5rem

                .tags
                    display flex

                    .tag
                        color $yellow
                        border 1px solid $yellow
                        border-radius 4px
                        font-size 12px
                        line-height 14.4px
                        padding 0 3px

    .conds_wrapper
        display flex
        flex-direction column
        position relative
        padding 8px
        height 100%

        .cond_item
            border-bottom 1px solid $border_color
            margin .75rem 0 0
            padding 0 .75rem .75rem 0

            .title
                padding .5rem

            .cent
                display flex
                flex-wrap wrap
                padding-left 1rem

            .icon-flag
                width 14%
                text-align center
                padding-top 4px
                border 1px solid transparent

                &.checked
                    border-radius 4px
                    border 1px solid $active

            .cent_item
                border-radius 4px
                border 1px solid #ccc
                margin-bottom .5rem
                margin-right .5rem
                padding .25rem .5rem
                font-size 14px

                &.active
                    border 1px solid $active
                    color $active
                    background-color #fff4f0

        .footer
            width calc(100% - 16px)
            bottom 50px
            position absolute
            padding .5rem

            button
                width 45%

            button + button
                margin-left 10%
</style>
