<template lang="pug">
    van-popup.before-print(v-model="show" position="bottom" :style="{ height: '60%' }")
        van-picker.set(show-toolbar v-if="!showPrinter" @cancel="show = false" @confirm="onConfirm" confirm-button-text="打印" title="选择打印配置")
            template(#columns-top)
                van-radio-group(v-model="radio")
                    emptyView(v-if="!showPrinter && templateList.length === 0")
                    van-cell(v-for="it in templateList" v-if="!showPrinter && templateList.length > 0")
                        template(#title)
                            p.f13.bold_font
                                i.op_icon.iconfont.icon-kuaidiwuliu
                                span.ml5 {{it.name}}
                            p.f12
                                i.op_icon.iconfont.icon-shop1
                                span.second-title.ml5 {{it.mallName}}
                            p.f12
                                i.op_icon.iconfont.icon-dayinji1
                                span(v-if="it.printer && it.printer.name"  @click="showBindPrinter(it)").second-title.ml5 {{it.printer.name}}
                                a(v-else @click="showBindPrinter(it)").active.ml5 绑定打印机
                        template(#right-icon)
                            .right-cell
                                p.second-title.f12 余额：{{it.quantity}}
                                van-radio.ml10(:name="it.id")
        van-picker(title="选择打印机" show-toolbar v-if="showPrinter" :columns="bindPrinterNameList" @confirm="bindPrinter" @cancel="showPrinter=false")
</template>

<script>
  import {mapActions, mapGetters, mapMutations} from "vuex";
  import errorView from '@/common/errorView'
  import utils from '@/common/utils'
  import pdd from '@/bluetooth/pdd'
  import {Toast} from 'vant';
  import customArea from '@/common/customArea'
  import emptyView from '../common/emptyView'

  const bindPrinterListKey = 'bindPrinterList';
  const templateAndPrinterKey = 'templateAndPrinter';


  export default {
    components:{emptyView},
    data() {
      return {
        radio: null,
        show: false,
        showPrinter: false,
        bindPrinterList: [],
        bindPrinterNameList: [],
        currentTemplate: {},
        orderList: [],
        printDatas: {},
      }
    },
    computed: {
      ...mapGetters(['templateList', 'shippingAddressList', 'shopList', 'commonStdTemplates'])
    },
    mounted() {
      this.getCharacteristicsUuId();
    },
    methods: {
      ...mapActions(['updateWaybillBalanceAction']),
      ...mapMutations(['updateTemplatePrinter']),

      getCharacteristicsUuId() {
        pdd.bluetoothListener('getCharacteristicsUuId', (characteristicsUuId) => {
          if (characteristicsUuId) {
            //请求后端接口获取打印机需要的数据
            let {data, template, orderSn2WaybillCode} = this.printDatas;
            //获取print_command_type
            let printName = template.printer.name, printCommandType = pdd.getCommandType(printName),
              content = {printName, printCommandType};
            //组装自定义区域的内容信息
            const {orders, orderSn2AliasName, skuAliasNameMap} = data,
              {content: printContent, shippingAddressId, mergeTemplateFontSize, parentTemplateId} = template;
            let checkedOrdersMap = this.orderList.reduce((accu, it) => ({...accu, [it.orderSn]: it}), {}), arr = {},
              totalList = []
            orders.filter(it => it.code === 1).forEach(it => {
              Object.keys(it.orderSnGoodMap).forEach(orderSn => {
                arr[orderSn] = orderSn2WaybillCode[orderSn] ? orderSn2WaybillCode[orderSn] : [];
                if (!arr[orderSn].includes(it.waybillCode)) {
                  arr[orderSn].push(it.waybillCode);
                }
              });
            });
            //找到标准模板的宽和高
            const {width, height} = this.commonStdTemplates.filter(i => i.templateId === parentTemplateId)[0];

            let current = 0, orderTotal = orders.length, printNotifyList = [];
            orders.filter(it => it.code === 1).forEach((it, index) => {
              current++;
              //自定义的内容
              let custom = {}, order = {}, currentOwnerId = '', obj = {waybillCode: it.waybillCode};
              Object.keys(it.orderSnGoodMap).forEach(orderSn => {
                if (checkedOrdersMap[orderSn]) {
                  order = checkedOrdersMap[orderSn];
                  // 注入商品别名,sku别名
                  order.aliasName = orderSn2AliasName[order.goodsId]
                  order.skuAliasName = skuAliasNameMap[order.skuId]
                  if (order.mergeOrders) {
                    order.mergeOrders.forEach(mOrder => {
                      mOrder.aliasName = orderSn2AliasName[mOrder.goodsId];
                      mOrder.skuAliasName = skuAliasNameMap[mOrder.skuId]
                    })
                  }
                }
              });
              currentOwnerId = order.ownerId;
              let list = this.shopList.filter(i => i.ownerId === currentOwnerId), currentShop = {};
              if (!list || list.length === 0) {
                currentShop = {name: order.mallName}
              }
              //设置发件人信息
              let addr = this.getDefaultAddress(shippingAddressId, currentOwnerId);
              obj.addData = JSON.stringify(addr);
              let printData = {}, printDataList = [];
              if (order.mergeOrders && order.mergeOrders.length > 0) {
                //合单情况
                //有备注的订单放在下方
                let orders = [order, ...order.mergeOrders];
                orders.sort(function (a, b) {
                  if (a.remark) {
                    return 1;
                  } else {
                    return -1;
                  }
                })
                let count = order.goodsCount;
                order.mergeOrders.forEach(i => count += i.goodsCount);
                //判断有没有店铺名称
                let objList = JSON.parse(printContent);
                let nameList = objList.filter(it => it.value === '<%=data.mallName%>'),
                  numberList = objList.filter(it => it.value === '<%=data.printNo%>');
                printDataList.push({
                  fontSize: mergeTemplateFontSize ? mergeTemplateFontSize * 2 : 28,
                  height: height * 8,
                  left: 0,
                  text: `【合并订单】订单数：${order.mergeOrders.length + 1}，总件数：${count} \n ${customArea.getCustomAreaTemplateByMerge(printContent, currentShop, orders)}`,
                  top: 0,
                  width: width * 8 - 50
                })
                if (nameList && nameList.length > 0) {
                  printDataList.push({
                    fontSize: mergeTemplateFontSize ? mergeTemplateFontSize * 2 : 28,
                    height: height * 8 - 40,
                    left: 0,
                    text: currentShop.name,
                    top: 0,
                    width: width * 8 - 50,
                    valign: 'bottom',
                    align: 'right'
                  })
                }
                if (numberList && numberList.length > 0) {
                  printDataList.push({
                    fontSize: mergeTemplateFontSize ? mergeTemplateFontSize * 2 : 28,
                    height: height * 8 - 40,
                    left: 0,
                    text: `当前第${current}页，共${orderTotal}页`,
                    top: 0,
                    width: width * 8 - 50,
                    valign: 'bottom',
                    align: 'left'
                  })
                }
                printData.data = {
                  list: printDataList,
                  waterdata: customArea.getWatermarkInfo(printContent, null, count)
                }
              } else {
                printData.data = {
                  list: customArea.getCustomAreaTemplate(printContent, currentShop, order, arr[order.orderSn], it.waybillCode, current, orderTotal),
                  waterdata: customArea.getWatermarkInfo(printContent, order, null)
                }
              }
              obj.printData = JSON.stringify(printData);
              totalList.push(obj);

              printNotifyList.push({
                waybillCode: it.waybillCode, source: 0, index: index + 1, senderName: addr.name,
                senderPhone: addr.mobile,
                senderAddress: addr.province + addr.city + addr.district + addr.detail
              });
            });
            content.orderList = totalList;
            this.$http.cmdPrint(content).then(res => {
              //校验成功，失败的情况
              const list = res.data, fails = list.filter(it => it.code === 0),
                successList = list.filter(it => it.code === 1);
              if (fails.length > 0) {
                this.showLoadingMessage(false);
                return new Promise((resolve, reject) => {
                  errorView.showCloudPrintErrorModal(fails, resolve, res.data, reject).then(data => {
                    if (successList.length > 0) {
                      this.realPrint(characteristicsUuId, successList, printNotifyList);
                    } else {
                      Toast.fail('所有订单取号均失败，无法打印！')
                    }
                  }).catch(() => console.log('取消打印'))
                })
              } else {
                this.showLoadingMessage(false);
                this.realPrint(characteristicsUuId, successList, printNotifyList);
              }
            })
          }
        })
      },

      realPrint(characteristicsUuId, successList, printNotifyList) {

        this.showLoadingMessage(true, '正在打印');
        pdd.printInfo(characteristicsUuId, successList.map(i => i.printData)).then(res => {
          this.showLoadingMessage(false);
          Toast.success('打印成功');
          this.show = false;
          //打印通知
          let codeList = successList.map(it=>it.waybillCode);
          printNotifyList = printNotifyList.filter(it=>codeList.includes(it.waybillCode));
          if (printNotifyList && printNotifyList.length > 0) {
            this.$http.notifyPrintSuccess({list: printNotifyList, batchNo: new Date().getTime()})
          }
        })
      },

      getDefaultAddress(shippingAddressId, currentOwnerId) {
        let defaultAddress = {};
        if (shippingAddressId) {
          let list = this.shippingAddressList.filter(it => it.id === shippingAddressId);
          if (list && list.length > 0) {
            defaultAddress = list[0];
          }
        }
        if (!defaultAddress.id) {
          let list = this.shippingAddressList.filter(it => it.ownerId === currentOwnerId);
          if (!list || list.length === 0) {
            defaultAddress = this.shippingAddressList.filter(it => it.isDefault)[0];
          } else if (list.length === 1) {
            defaultAddress = list[0];
          } else {
            const defaultA = list.filter(it => it.isDefault);
            if (defaultA.length > 0) {
              defaultAddress = defaultA[0];
            } else {
              defaultAddress = list[0];
            }
          }
        }
        return {
          name: defaultAddress.senderName,
          mobile: defaultAddress.mobile,
          address: {
            province: defaultAddress.province,
            city: defaultAddress.city,
            district: defaultAddress.district,
            detail: defaultAddress.address
          }
        };
      },

      boot(orderList) {
        this.show = true;
        let list = utils.get(bindPrinterListKey);

        if (list && list.length > 0) {
          this.bindPrinterList = [...list];
          this.bindPrinterNameList = this.bindPrinterList.map(it => it.name)
        }
        this.orderList = orderList;
        this.updateWaybillBalanceAction();
      },
      showBindPrinter(obj) {
        this.currentTemplate = {...obj};
        this.showPrinter = true;
      },
      onConfirm() {
        let templates = this.templateList.filter(it => it.id === this.radio);
        if (!templates || templates.length === 0) {
          Toast.fail('请选择快递模板');
          return;
        }
        let template = templates[0];
        if (!template.printer || !template.printer.name) {
          Toast.fail('请选择打印机');
          return;
        }
        if (!this.shippingAddressList || this.shippingAddressList.length === 0) {
          Toast.fail('请设置默认发货地址');
          return;
        }

        //准备数据，校验数据
        this.printData(template);
        //取号
        // this.show = false;
      },
      showLoadingMessage(flag, msg = '正在取号，请稍等') {
        if (flag) {
          Toast.loading({
            message: msg,
            duration: 0
          });
        } else {
          Toast.clear()
        }
      },
      async printData(template) {
        //校验面单余额
        if (template.source === 1 && template.quantity !== '无限') {
          //获取单号并打印需要校验分享的面单的余额够不够
          if (this.orderList.length > template.quantity) {
            Toast.fail('您的面单余额不足，请先充值！');
            return;
          }
        }
        //组装数据
        let {orderPrintList, sn2Order, orderSn2WaybillCode, orderSns} = this.generatePrintData();
        this.showLoadingMessage(true);

        //校验数据
        const res = await
          this.$http.validOrder(orderSns);
        const map = res.data, validFailed = [];
        for (let orderSn of Object.keys(map)) {
          if (map[orderSn] !== 'PASS') {
            const [afterStatus, refundStatus, orderStatus] = map[orderSn].split(',');
            validFailed.push({orderSn, obj: sn2Order[orderSn], afterStatus, refundStatus, orderStatus})
          }
        }
        if (validFailed.length > 0) {
          this.showLoadingMessage(false);
          errorView.showValidResult(validFailed).then(() => {
            this.showLoadingMessage(true);
            this.getWaybillCode(orderPrintList, template, orderSn2WaybillCode)
          }).catch(() => {
            console.log('取消本次打印')
          })
        } else {
          this.getWaybillCode(orderPrintList, template, orderSn2WaybillCode);
        }
      },
      getWaybillCode(orderPrintList, template, orderSn2WaybillCode) {
        const batchNo = new Date().getTime();
        let params = {
          templateId: template.id,
          orderSnList: orderPrintList,
          batchNo: batchNo
        };
        this.$http.getWaybillCode(params).then(res => {
          this.printDatas = {orderSn2WaybillCode, template, data: res.data};
          const {orders} = res.data, fails = orders.filter(it => it.code === 0),
            successList = orders.filter(it => it.code === 1);
          if (fails.length > 0) {
            this.showLoadingMessage(false);
            return new Promise((resolve, reject) => {
              errorView.showErrorModal(fails, resolve, res.data, reject).then(data => {
                if (successList.length > 0) {
                  this.cloudPrint(template);
                } else {
                  Toast.fail('所有订单取号均失败，无法打印！')
                }
              }).catch(() => console.log('取消打印'))
            })
          } else {
            this.cloudPrint(template);
          }
        }).catch(err => {
          this.showLoadingMessage(false);
          Toast.fail('取号失败，请稍后重试！');
        })
      },
      cloudPrint(template) {
        //先连接打印机获取特征值，看是否成功
        try {
          pdd.connectBluetoothDevice(template.printer)
        } catch (e) {
          this.showLoadingMessage(false)
          Toast.fail(e.message)
        }
      },
      generatePrintData() {
        const orderPrintList = [], sn2Order = {}, orderSn2WaybillCode = {};
        this.orderList.forEach(it => {
          orderSn2WaybillCode[it.orderSn] = it.waybillCode ? it.waybillCode.split(',') : [];
          const arr = [it.orderSn];
          sn2Order[it.orderSn] = it;
          if (it.mergeOrders) {
            arr.push(...it.mergeOrders.map(it2 => {
              sn2Order[it2.orderSn] = it2;
              return it2.orderSn;
            }));
          }
          orderPrintList.push({sns: arr, num: 0, packageNum: 0})
        });
        const orderSns = Object.keys(sn2Order);
        return {orderPrintList, sn2Order, orderSn2WaybillCode, orderSns};
      },
      bindPrinter(val) {
        let printer = this.bindPrinterList.filter(it => it.name === val)[0],
          obj = {printer, templateId: this.currentTemplate.id};
        this.updateTemplatePrinter(obj);
        let relation = utils.get(templateAndPrinterKey);
        if (!relation) {
          relation = [];
        }
        relation = relation.filter(it => it.templateId !== this.currentTemplate.id);
        relation.push(obj);
        utils.set(templateAndPrinterKey, relation)
        Toast.success('绑定成功');
        this.showPrinter = false;
      }
    }
  }

</script>

<style lang="stylus">
    .before-print
        .van-picker__cancel
            font-size 13px
        .van-picker__confirm
            font-size 13px
            color #d43
        .van-picker__title
            font-size 13px
        .set
            .van-picker__columns
                display none
        .van-radio__icon--checked .van-icon
            background $active
            border-color $active
        .right-cell
            display flex
            align-items center
        .active
            color: $active
        .second-title
            color: #ccc
</style>
