import utils from "./utils";

const getCustomAreaTemplateByMerge = function (content, shop, orderList) {
  let objList = JSON.parse(content);
  objList = objList.filter(it => it.value !== '<%=data.watermark%>' && it.value !== '<%=data.mallName%>' && it.value !== '<%=data.printNo%>');
  if (!objList || objList.length === 0) {
    return '';
  }
  let contents = '', keys = ['<%=data.remark%>', '<%=data.popRemark%>', '<%=data.systemRemark%>'];
  const remarks = objList.filter(it => keys.includes(it.value));
  if (remarks && remarks.length > 0) {
    objList = objList.filter(it => !keys.includes(it.value));
    objList = objList.concat(remarks)
  }
  orderList.forEach(item => {
    objList.forEach(it => {
      if (it.value && it.value.indexOf('<%') === -1) {
        return;
      }
      let {realValue} = getValue(it.value, shop, item, true, null, null, 0, 0);
      realValue = parse(it.value, realValue)
      if (keys.includes(it.value) && realValue) {
        contents += '\n' + realValue;
      } else {
        contents += realValue + ' ';
      }
    });
    contents += '\n ';
  });
  let customs = objList.filter(it => it.custom);
  if (customs && customs.length > 0) {
    customs.forEach(it => contents += ` ${it.value} `);
  }
  return contents;
};

const getCustomAreaTemplate = function (content, shop, order, waybillCodes, waybillCode, current, total) {
  let list = [], objList = JSON.parse(content);
  objList = objList.filter(it => it.value !== '<%=data.watermark%>');
  if (!objList || objList.length === 0) {
    return list;
  }
  objList.forEach(it => {
    let {realValue} = getValue(it.value, shop, order, false, waybillCodes, waybillCode, current, total);
    list.push({fontSize:it.fontSize*2,height:it.height *2,width:it.width*2,top:it.top*2,left:it.left*2,text:parse(it.value, realValue)});
  });
  return list;
};

const getWatermarkInfo = function (content, order, count) {
  let objList = JSON.parse(content);
  const list = objList.filter(it => it.value === '<%=data.watermark%>');
  const result = {text: '', top: 0, left: 0};
  if (!list || list.length === 0) {
    return result;
  }
  const it = list[0];
  if (!count && (!order || !order.goodsCount)) {
    return result;
  }
  return {fontSize:it.fontSize*2,height:it.height *2,width:it.width*2,top:it.top*2,left:it.left*2,text:parse(it.value, count ? count : order.goodsCount)}
};

function getValue(value, shop, order, merge, waybillCodes, waybillCode, current, total) {
  let realValue, keyName;
  const skuName = order.skuAliasName ? order.skuAliasName : order.goodsSpec;
  switch (value) {
    case '<%=data.allProductDetail%>': {
      let productName = order.aliasName ? order.aliasName : (order.goodsName ? order.goodsName : '');
      if (productName) {
        realValue = productName + ',';
      }
      if (skuName) {
        realValue += skuName;
      }
      if (order.goodsCount) {
        realValue += ' X' + order.goodsCount + '件';
      }
      keyName = 'allProductDetail';
      break;
    }
    case '<%=data.package%>':
      if (!merge && waybillCodes && waybillCodes.length > 1) {
        realValue = `多包${waybillCodes.findIndex(it => it === waybillCode) + 1}/${waybillCodes.length} ${waybillCodes.toString()}`;
      }
      keyName = 'package'
      break;
    case '<%=data.standardAndCount%>': {
      if (skuName && order.goodsCount) {
        realValue = skuName + ' X' + order.goodsCount + '件';
      } else if (skuName) {
        realValue = skuName;
      } else if (order.goodsCount) {
        realValue = ' X' + order.goodsCount + '件';
      } else {
        realValue = '';
      }
      keyName = 'standardAndCount';
      break;
    }
    case '<%=data.mallName%>':
      realValue = shop.name;
      keyName = 'mallName';
      break;
    case '<%=data.printNo%>':
      realValue = `当前第${current}页，共${total}页`;
      keyName = 'printNo';
      break;
    case '<%=data.confirmTime%>':
      realValue = order.confirmTime ? utils.formateDate(new Date(Number(order.confirmTime))) : '';
      keyName = 'confirmTime';
      break;
    case '<%=data.orderNum%>':
      realValue = order.orderSn;
      keyName = 'orderNum';
      break;
    case '<%=data.productName%>':
      realValue = order.aliasName ? order.aliasName : order.goodsName;
      keyName = 'productName';
      break;
    case '<%=data.ownerName%>':
      realValue = shop.ownerName;
      keyName = 'ownerName';
      break;
    case '<%=data.productStandard%>':
      realValue = skuName;
      keyName = 'productStandard';
      break;
    case '<%=data.count%>':
      realValue = order.goodsCount;
      keyName = 'count';
      break;
    case '<%=data.money%>':
      realValue = order.goodsPrice;
      keyName = 'money';
      break;
    case '<%=data.totalPrice%>':
      realValue = order.goodsPrice ? Number(order.goodsPrice) * Number(order.goodsCount) : '';
      keyName = 'totalPrice';
      break;
    case '<%=data.payAmount%>':
      realValue = order.payAmount;
      keyName = 'payAmount';
      break;
    case '<%=data.remark%>':
      realValue = [order.remark, order.popRemark].filter(it => it).join(';');
      keyName = 'remark';
      break;
    case '<%=data.popRemark%>':
      realValue = order.popRemark;
      keyName = 'popRemark';
      break;
    case '<%=data.systemRemark%>':
      realValue = order.remark;
      keyName = 'systemRemark';
      break;
    case '<%=data.buyerMemo%>':
      realValue = order.buyerMemo;
      keyName = 'buyerMemo';
      break;
    case '<%=data.goodsId%>':
      realValue = order.goodsId;
      keyName = 'goodsId';
      break;
    case '<%=data.skuId%>':
      realValue = order.skuId;
      keyName = 'skuId';
      break;
    case '<%=data.outerGoodsId%>':
      realValue = order.outerGoodsId;
      keyName = 'outerGoodsId';
      break;
    case '<%=data.outerId%>':
      realValue = order.outerId;
      keyName = 'outerId';
      break;
    case '<%=data.SKUAndCount%>': {
      let outerId = order.outerId, goodsCount = '';
      if (order.goodsCount) {
        goodsCount = 'X' + order.goodsCount + '件';
      }
      realValue = outerId + " " + goodsCount;
      keyName = 'SKUAndCount';
      break;
    }
    default:
      realValue = value;
      keyName = value;
  }
  return {realValue, keyName}
}

function parse(key, value) {
  if (!value) {
    return '';
  }
  switch (key) {
    case '<%=data.watermark%>':
      return value + '件';
    case '<%=data.count%>':
      return 'X' + value + '件';
    case '<%=data.money%>':
      return value + '元';
    case '<%=data.totalPrice%>':
      return '总价：' + value + '元';
    case '<%=data.payAmount%>':
      return '支付金额：' + value + '元';
    case '<%=data.remark%>':
      return '【备注】：' + value;
    case '<%=data.popRemark%>':
      return '【拼多多备注】：' + value;
    case '<%=data.systemRemark%>':
      return '【软件备注】：' + value;
    default:
      return value;
  }

}

export default {
  getCustomAreaTemplateByMerge,
  getCustomAreaTemplate,
  getWatermarkInfo
}
