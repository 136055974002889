const flagList = [
    {
        value: 'GRAY',
        label: '灰色'
    }, {
        value: 'RED',
        label: '红色'
    }, {
        value: 'YELLOW',
        label: '黄色'
    }, {
        value: 'GREEN',
        label: '绿色'
    }, {
        value: 'BLUE',
        label: '蓝色'
    }, {
        value: 'PURPLE',
        label: '紫色'
    }];

export default {
    flagList
}