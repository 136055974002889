import {Dialog} from 'vant';
import config from "@/common/config";


function showValidResult(validFailed) {
  return new Promise((resolve, reject) => {
    let str = '';
    validFailed.map(it => {
      const {orderSn, obj, afterStatus, refundStatus, orderStatus} = it;
      if (orderStatus) {
        str += `<div style="color:red">订单: ${orderSn}, ${orderStatus == 2 ? '已发货' : '已签收'}</div>`;
      } else {
        const afterStr = config.afterStatusMap[afterStatus],
          refundStr = config.refundStatus[refundStatus];
        str += `<div style="color:red">订单: ${orderSn}, 售后状态: ${afterStr}, 退款状态: ${refundStr}</div>`
      }
    });
    Dialog.confirm({
      title: '确认要打印吗？',
      message: str,
      confirmButtonText: '继续打印',
      cancelButtonText: '取消打印'
    }).then(() => {
      resolve();
    }).catch(() => {
      reject();
    });
  })
}

const error_msg = {
  "1000": "收件人相关信息缺失，获取单号失败",
}

function showErrorModal(fails, resolve, data, reject) {
  const tableData = fails.map(it => {
    const {errorMsg, orderSnGoodMap} = it,
      sns = Object.keys(orderSnGoodMap),
      obj = errorMsg.startsWith("{") && errorMsg.endsWith("}") ? JSON.parse(errorMsg) : {};
    let msg = obj.subMsg ? obj.subMsg : errorMsg;
    msg = error_msg[obj.subCode] ? error_msg[obj.subCode] : msg;
    return {sns, msg};
  });

  const allLength = data.orders.length, failLength = fails.length, successLength = allLength - failLength;
  let str = `<p class="mb12">成功: ${successLength}个, 失败: ${failLength}个</p><table border="1" cellpadding="0" cellspacing="0">`
  tableData.map(it => {
    str += `<tr><td>${it.sns.join(',')}</td><td>${ it.msg}</td></tr>`
  });
  return new Promise((resolve, reject) => {
    Dialog.confirm({
      message: str,
      title: '部分面单获取失败',
      confirmButtonText: '打印成功订单',
      cancelButtonText: '取消打印'
    }).then(() => resolve(data))
      .catch(() => reject())
  })
}


function showCloudPrintErrorModal(fails, resolve, data, reject) {
  const tableData = fails.map(it => {
    const {errorMsg, orderSn, waybillCode} = it,
      obj = errorMsg.startsWith("{") && errorMsg.endsWith("}") ? JSON.parse(errorMsg) : {};
    let msg = obj.subMsg ? obj.subMsg : errorMsg;
    msg = error_msg[obj.subCode] ? error_msg[obj.subCode] : msg;
    return {orderSn, msg, waybillCode};
  });

  const allLength = data.length, failLength = fails.length, successLength = allLength - failLength;
  let str = `<p class="mb12">成功: ${successLength}个, 失败: ${failLength}个</p><table border="1" cellpadding="0" cellspacing="0">`
  tableData.map(it => {
    str += `<tr><td>${it.orderSn}</td><td>${it.waybillCode}</td><td>${ it.msg}</td></tr>`
  });
  return new Promise((resolve, reject) => {
    Dialog.confirm({
      message: str,
      title: '部分面单拼多多组装数据失败',
      confirmButtonText: '打印成功订单',
      cancelButtonText: '取消打印'
    }).then(() => resolve(data))
      .catch(() => reject())
  })
}

function showSendGoodsError(total, fails, list, msg = '发货') {
  const failLength = fails.length, reqMap = list.reduce((accu, val) => {
    accu[val.orderSn] = val;
    return accu;
  }, {});
  let str = `<p class="mb12">请求${msg}${total}个， 成功: ${total - failLength}个, 失败: ${failLength}个</p><table border="1" cellpadding="0" cellspacing="0">`
  str += '<tr><td style="width:30%">订单号</td><td style="width:30%">面单号</td><td style="width:40%">错误信息</td></tr>';
  fails.forEach(it => {
    let msg = it.errorMsg, reqItem = reqMap[it.orderSn];
    if (it.errorMsg && it.errorMsg.startsWith("{") && it.errorMsg.endsWith("}")) {
      const errObj = JSON.parse(it.errorMsg), {errorCode, subCode, subMsg, errorMsg: err2} = errObj;
      msg = subMsg ? subMsg : err2;
      if (50001 === errorCode && "2" === subCode) {
        msg += ",请核对快递单号与物流公司是否对应";
      } else if (50001 === errorCode && "8" === subCode) {
        msg = '订单已在别的平台发货，您可以回收单号';
        // isSend = true;
      } else if (10019 === errorCode && "10019" === subCode) {
        msg = '拼多多平台授权过期，请重新登录';
      } else if (70031 === errorCode && "70031" === subCode) {
        msg = '拼多多接口抽风了，请5分钟后再试';
      }
    }
    str += `<tr><td>${it.orderSn}</td><td>${reqItem.waybillCode}</td><td>${msg}</td></tr>`
  });
  return new Promise((resolve) => {
    Dialog.alert({
      message: str,
      title: `部分订单${msg}失败`
    }).then(() => resolve())
  })
}


export default {
  showValidResult,
  showErrorModal,
  showCloudPrintErrorModal,
  showSendGoodsError
}
